import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"

export const cn = (...classes: (string | undefined | unknown)[]) => classes.filter(Boolean).join(" ")


const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
            "z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
            className
        )}
        {...props}
    />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

function TooltipItem (props: {children: React.ReactNode, tip: string}) {
    return <TooltipProvider>
        <Tooltip>
            <TooltipTrigger>
                {props.children}
            </TooltipTrigger>
            <TooltipContent className="bg-white">
                <p>{props.tip}</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipItem }
